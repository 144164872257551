












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_CURRENT_TERMS_OF_SERVICE_CONTENT } from '@/store/user/actions.type'
import { SimpleServerResponse } from '@/store/types'
@Component
export default class Dashboard extends Vue {
  @Action(GET_CURRENT_TERMS_OF_SERVICE_CONTENT)
  public getCurrentTermsOfServiceContent: (locale: string) => Promise<SimpleServerResponse>

  termsOfService = ''

  get correctTermsOfService (): string {
    this.getCurrentTermsOfServiceContent(this.$root.$i18n.locale).then(data => {
      this.termsOfService = data.message
    }, error => {
      this.$root.$emit('alert', this.$i18n.t('warning.error!'), this.$i18n.t('terms-of-service.' + error.message), true)
    })
    return this.termsOfService
  }
}
